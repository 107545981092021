export const DH = {
  fullDateLocation: 'ev-full-date-location',
  listImagePlaceholder: 'ev-list-image-placeholder',
  listImage: 'ev-list-image',
  listTitle: 'list-title',
  listItem: 'event-list-item',
  cards: 'events-cards',
  card: 'events-card',
  image: 'image',
  eventTitle: 'event-title',
  singleDate: 'date-container',
  singleImage: 'ev-image',
  singleLocation: 'location-container',
  rsvpButton: 'ev-rsvp-button',
  members: 'members-avatars',
  loadMoreButton: 'load-more-button',
}
