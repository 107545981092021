import classNames from 'classnames'
import React from 'react'
import {Spinner} from 'wix-ui-tpa/Spinner'
import {useWidgetState, useWidgetActions} from '../../hooks/state-provider'
import {hasMoreEvents, isEventsLoading} from '../../selectors/events'
import {DH} from '../../utils/data-hooks'
import {LoadMoreButtonProps} from './interfaces'
import * as s from './load-more-button.scss'

export const LoadMoreButton = ({t}: LoadMoreButtonProps) => {
  const hasMore = useWidgetState(hasMoreEvents)
  const loading = useWidgetState(isEventsLoading)
  const {loadMoreEvents} = useWidgetActions()

  if (!hasMore && !loading) {
    return null
  }

  if (loading) {
    return (
      <div className={classNames(s.loader, s.positioning)}>
        <Spinner />
      </div>
    )
  }

  return (
    <button
      data-hook={DH.loadMoreButton}
      className={classNames(s.button, s.positioning)}
      type="button"
      onClick={() => loadMoreEvents()}
    >
      {t('loadMoreButton')}
    </button>
  )
}
